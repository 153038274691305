import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import classes from '../pos/PosPage.module.css'
import { loadStoreProducts } from '../../services/productServices'
import { useEffect, useState } from 'react'
import AlertComponent from '../../components/alertComponent'
import LoaderDialogue from '../../components/loaderDialogueComponent'
import { useSelector } from 'react-redux'
import extraclass from '../checkout/CheckoutPage.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { getOneSubscriberBalance } from '../../services/productServices'
import { completeCheckout } from '../../services/storeServices'
import { LoadingButton } from '@mui/lab'
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import mixpanel from 'mixpanel-browser'
import CloseRegisterForm from '../../components/closeRegisterForm'
import { refreshToken } from '../../services/userServices'
import { useNavigate } from 'react-router-dom'
import { makeCaptureRequest, makeVerificationRequest } from '../../services/storeServices'
import BiometricScanDialogue from '../../components/biometricScanDialogue'
import { useDispatch } from 'react-redux'
import { emptyCart } from '../../state/redux/cartSlice'
import Button from '@mui/material/Button';
import { IoMdFingerPrint } from "react-icons/io";
import Cookies from 'js-cookie'
import { secondaryColour } from '../../utils/colors'
import { MdPersonSearch } from "react-icons/md";
import { captureprint } from '../../services/storeServices'
import { useCallback, useRef } from 'react'
import debounce from 'lodash.debounce'
import { flushSync } from 'react-dom'


const generateOrderNumber = () => {
    return Math.floor(Math.random() * 1000000000)

}

var cardcode

function CheckoutPage
    () {


    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [cardCode, setCardCode] = useState("")
    const [cardPin, setCardPin] = useState("")
    const [subscriberName, setSubscriberName] = useState('-')
    const [subscriberBalance, setSubscriberBalance] = useState('')
    const [amountPaid, setAmountPaid] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState('Fingerprint')
    const [showMealPlanForm, setShowMealPlanForm] = useState(true)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [disablePinInput, setDisablePinInput] = useState(true)
    const [loading, setLoading] = useState(false)
    const [change, setChange] = useState(0)
    const [disableOrderButton, setDisableOrderButton] = useState(true)
    const [storeSettings, setStoreSettings] = useState({})
    const [orderNumber, setOrderNumber] = useState(0)
    const [scannedBiometricData, setScannedBiometricData] = useState("")
    const [openBiometricScanDialogue, setOpenBiometricScanDialogue] = useState(false)
    const [biometricScanDialogueText, setBiometricScanDialogueText] = useState("")
    const [subscriberIsReadyForTransaction, setSubscriberIsReadyForTransaction] = useState(false)
    const [subscriberBioData, setSubscriberBioData] = useState([])
    const [disableFingerCapture, setDisableFingureCapture] = useState(true)
    const [biometricVerificationPassed, setBiometricVerificationPassed] = useState(false)
    const [biometricVerificationFailed, setBiometricVerificationFailed] = useState(false)
    const [biometricCaptureSuccessful, setBiometricCaptureSuccessful] = useState(false)
    const [bioDataVerifiedResponseOrNot, setBioDataVerifiedResponseOrNot] = useState(false)




    const cart = useSelector(state => state.cart)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const receiptDate = new Date();


    const paymentChoices = {
        cashChoice: 0,
        mealPlanChoice: 0,
        fingerprintChoice: 0
    }
    let printFlag = false

    const getTotalQuantity = () => {
        let total = 0
        cart.forEach(element => {
            total += element.quantity
        });
        return total
    }

    const closeBiometricScanDialogue = () => {
        setOpenBiometricScanDialogue(false)
    }
    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const getTotal = () => {
        let totalQuantity = 0
        let totalPrice = 0

        cart.forEach(item => {
            totalQuantity += item.qty
            totalPrice += item.cost * item.qty
        })
        return { totalPrice, totalQuantity }
    }

    const storesSettings = JSON.parse(localStorage.getItem('storeSettings'))
    storesSettings.map(item => {
        { item.show_cash_payment_option == 1 ? paymentChoices.cashChoice = 1 : null }
        { item.show_fingerprint_meal_plan_payment_option == 1 ? paymentChoices.fingerprintChoice = 1 : null }
        { item.show_meal_plan_payment_option == 1 ? paymentChoices.mealPlanChoice = 1 : null }

    })

    const initiateFingerCapture = () => {
        makeCaptureRequest().then(
            response => {
                if (response.data.status === "success") {
                    alert('captured')
                    setScannedBiometricData(response.data.template)
                } else if (response.data.status === "fail") {

                }

            }
        ).catch()
    }

    const getSubscriberBalance = (cardcode) => {
        if (cardcode === "") {
            setDisableOrderButton(true)
            return
        }
        getOneSubscriberBalance(cardcode).then(
            response => {
                
                if (response.data.status === "success") {
                    setSubscriberName(`${response.data.firstname} ${response.data.lastname}`)
                    setSubscriberBalance(response.data.current_balance)
                    setSubscriberBioData([...response.data.biodata])
                    if (response.data.card_status === 0) {
                        setOpenAlert(true)
                        setOpenLoaderDialogue(false)
                        setAlertMessage("The subscriber's account is inactive")
                        setDisablePinInput(true)
                        setDisableOrderButton(true)
                        setSubscriberIsReadyForTransaction(false)
                        setDisableFingureCapture(true)
                    } else if (response.data.amount < getTotal().totalPrice || response.data.current_balance < getTotal().totalPrice) {
                        setOpenAlert(true)
                        setOpenLoaderDialogue(false)
                        setAlertMessage("The subscriber's balance is insufficient to make this transaction")
                        setDisableOrderButton(true)
                        setSubscriberIsReadyForTransaction(false)
                        setDisableOrderButton(true)
                        setDisableFingureCapture(true)
                    } else {

                        setOpenLoaderDialogue(false)
                        setDisablePinInput(false)
                        setSubscriberIsReadyForTransaction(true)
                        setDisableFingureCapture(false)
                    }

                } else {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('You may have entered a wrong card code, please try again')
                    setDisableOrderButton(true)
                    setDisableFingureCapture(true)
                }

            }
        ).catch(error => {
            // setOpenLoaderDialogue(false)
            console.log(error)
            if (error.response.status === 401) {
                // setOpenLoaderDialogue(true)
                // setLoaderDialogueText('POS is refreshing your token.....')
                // refreshToken().then(
                //     response => {

                //         Cookies.set('bearer', response.data.access_token)
                //         handleSubscriberCardCodeEntry()
                //     }
                // )
            } else {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage("Error getting subscriber's info, please try again")
            }
        })
    }

    // const verifyBiometricData = () => {
    //     subscriberBioData.find(async (item) => {

    //         makeVerificationRequest(item.temp1, item.temp2, item.temp3).then(
    //             response => {
    //                 console.log(response)
    //                 if (response.data.score >= 65) {
    //                     setBiometricScanDialogueText("Print has been verified. Please complete transaction")
    //                     setBiometricVerificationPassed(true)
    //                     setDisableOrderButton(false)
    //                     return response.data.status == "success" && response.data.score >= 65
    //                 } else {
    //                     console.log(response)
    //                     setBiometricScanDialogueText("Verification failed, please try again")
    //                     setBiometricVerificationFailed(true)
    //                 }
    //             }
    //         )

    //     })
    // }

    const makecaptureRequest = () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'http://localhost:1234/?type=capture&temp1=ss&temp2=ss&temp3=ss');

            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    reject(new Error(`HTTP error ${xhr.status}: ${xhr.statusText}`));
                }
            };
            xhr.onerror = () => {
                reject(new Error('Network request failed'));
            };
            xhr.send();
        });
    };


    // const verifyBiometricData = async () => {
      
    //     for (const item of subscriberBioData) {
    //         try {
    //             const response = await makeVerificationRequest(item.temp1, item.temp2, item.temp3);
    //             if(response.data.status === "success" || response.data.status === "fail" ){
                    
    //                 setBioDataVerifiedResponseOrNot(true)
                    
    //             }
    //             if (response.data.status === "success" && response.data.score >= 65) {
                   
    //                 setBiometricScanDialogueText("Print has been verified. Transaction is completing...");
    //                 setBiometricVerificationPassed(true);
    //                 setDisableOrderButton(false);
                    
    //                 handlePaymentForm(event)

    //                 return true; // Exit the function as soon as a match is found
    //             }
    //         } catch (error) {
    //             console.error('Error verifying fingerprint:', error);
                
    //         }
    //     }
    //     setBiometricScanDialogueText("NOT VERIFIED,WRONG FINGER USED - Click 'Capture Fingerprint' button and let the subscriber try again ");
    //     setBiometricVerificationFailed(true)
    //     setBiometricVerificationPassed(false);
    //     // setBioDataVerifiedOrNot(false)
    //     return false; // No match found after checking all items
    // };

    // const verifyBiometricData = async () => {
    //     setBiometricScanDialogueText("Verifying fingerprint data...");
    
    //     try {
    //         // Create an array of verification promises
    //         const verificationPromises = subscriberBioData.map(item =>
    //             makeVerificationRequest(item.temp1, item.temp2, item.temp3)
    //         );
    
    //         // Use Promise.any to wait for the first successful verification
    //         const response = await Promise.any(verificationPromises);
    
    //         // Check the response status
    //         if (response.data.status === "success" && response.data.score >= 65) {
    //             setBiometricScanDialogueText("Print has been verified. Transaction is completing...");
    //             setBiometricVerificationPassed(true);
    //             setDisableOrderButton(false);
    //             handlePaymentForm(event);
    //             return true; // Exit the function as soon as a match is found
    //         } else {
    //             setBiometricScanDialogueText("NOT VERIFIED, WRONG FINGER USED - Click 'Capture Fingerprint' button and let the subscriber try again ");
    //             setBiometricVerificationFailed(true);
    //             setBiometricVerificationPassed(false);
    //             return false; // No match found after checking all items
    //         }
    //     } catch (error) {
    //         // Handle the case where all verifications fail
    //         setBiometricScanDialogueText("NOT VERIFIED, WRONG FINGER USED - Click 'Capture Fingerprint' button and let the subscriber try again ");
    //         setBiometricVerificationFailed(true);
    //         setBiometricVerificationPassed(false);
    //         console.error('Error verifying fingerprint:', error);
    //         return false;
    //     }
    // };

    const verifyBiometricData = async () => {
        const requests = subscriberBioData.map((item) => {
          return makeVerificationRequest(item.temp1, item.temp2, item.temp3);
        });
      
        try {
          const responses = await Promise.all(requests);
          for (const response of responses) {
            if (response.data.status === "success" && response.data.score >= 65) {
                flushSync(() => {
                    setBiometricScanDialogueText("Print has been verified. Transaction is completing...");
                });
              setBiometricVerificationPassed(true);
              setDisableOrderButton(false);
              handlePaymentForm(event);
              return true; // Exit the function as soon as a match is found
            }
          }
        } catch (error) {
          console.error('Error verifying fingerprint:', error);
        }
      
        setBiometricScanDialogueText("NOT VERIFIED,WRONG FINGER USED - Click 'Capture Fingerprint' button and let the subscriber try again ");
        setBiometricVerificationFailed(true);
        setBiometricVerificationPassed(false);
        return false; // No match found after checking all items
      };


    const initiateAndVerifyFingerCapture = () => {
        // let verificationcounter = 0
        // if (biometricVerificationPassed) {
        //     handlePaymentForm(event)
        //     return
        // }
        setBiometricVerificationFailed(false)
        setOpenBiometricScanDialogue(true)
        setBiometricScanDialogueText("Place finger on device to begin scanning")
        makeCaptureRequest().then(
            response => {
                if (response.data.status == "success") {
                    setScannedBiometricData(response.data.template)
                    setBiometricCaptureSuccessful(true)
                    setBiometricScanDialogueText("Scan Complete. Verifying... ") 
                    verifyBiometricData()
                } else {
                    alert("Capture failed")
                    setOpenBiometricScanDialogue(false)
                }

            }
        ).catch(
            error => {
                if (error) {
                    alert("An error occurred, please try again")
                }
            }
        )
    }

    const cashOption = {
        amountPaid: amountPaid,
        card_code: "",
        card_pin: "",
        deliveryCharge: 0,
        location: "",
        orderNo: orderNumber,
        orderType: "walk in",
        paymentMethod: "Cash",
        phone: "",
        verificationType: 'cash',
        registerID: localStorage.getItem('registerID'),
        saleItems: JSON.stringify(cart),
        storeID: localStorage.getItem('userStoreID'),
        subtotal: getTotal().totalPrice,
        total: getTotal().totalPrice + 0,
        userID: localStorage.getItem('userID')
    }

    const mealPlanOption = {
        amountPaid: 0,
        card_code: cardCode,
        card_pin: cardPin,
        deliveryCharge: 0,
        location: "",
        orderNo: orderNumber,
        orderType: "walk in",
        paymentMethod: paymentMethod,
        phone: "",
        verificationType: 'pin',
        registerID: localStorage.getItem('registerID'),
        saleItems: JSON.stringify(cart),
        storeID: localStorage.getItem('userStoreID'),
        subtotal: getTotal().totalPrice,
        total: getTotal().totalPrice + 0,
        userID: localStorage.getItem('userID')
    }

    const fingerprintOption = {
        amountPaid: 0,
        card_code: cardCode,
        card_pin: "",
        deliveryCharge: 0,
        location: "",
        orderNo: orderNumber,
        orderType: "walk in",
        paymentMethod: "Credit",
        phone: "",
        verificationType: 'fingerprint',
        registerID: localStorage.getItem('registerID'),
        saleItems: JSON.stringify(cart),
        storeID: localStorage.getItem('userStoreID'),
        subtotal: getTotal().totalPrice,
        total: getTotal().totalPrice + 0,
        userID: localStorage.getItem('userID')
    }

    const getAmountPaid = (event) => {
        setAmountPaid(event.target.value)
    }

    const getCardPin = (event) => {
        const pin = event.target.value
        setCardPin(pin)
        if (pin.length === 4 || pin.length == 6) {
            setDisableOrderButton(false)
        } else if (pin === "" || pin.length < 4) {
            setDisableOrderButton(true)
        }
    }

    const getPaymentMethod = (event) => {
        setPaymentMethod(event.target.value)
    }

    const getCardCode = (event) => {
        setCardCode(event.target.value)
    }


    const getBalance = (code) => {
        setOpenLoaderDialogue(true)
        setLoaderDialogueText("Loading Subscriber's Info")
        getSubscriberBalance(code)
    }

  

    const debouncedQuery = useCallback(
        debounce((code) => {
            if (code.length === 7) {
                getBalance(code)
            }
        }, 3000),
        []
    );

    
    
    
    const handleSubscriberCardCodeEntry = (event) => {
        const entry = event.target.value
        localStorage.setItem("CardCode",entry)
        setCardCode(entry)
        cardcode =entry
       
        debouncedQuery(cardcode)
        if (cardcode.length === 8) {
            //    stopTimer()
            getBalance(cardcode)
        }else if(cardcode.length === 7){
            debouncedQuery(cardcode)
        }
        if (cardcode === " ") {
           
            setDisableOrderButton(true)
            setDisableFingureCapture(true)
        }

        if (paymentMethod === "Fingerprint") {
            setBiometricVerificationPassed(false)
            setDisableFingureCapture(true)
            setDisableOrderButton(true)
        }
    }

    const getSubscriberInfoViaButton = (event) => {
       console.log(cardcode)
        getBalance(cardcode)
    }

    const handlePaymentForm = async (event) => {
        setLoading(true)
        event ?  event.preventDefault() : null
       
        if (paymentMethod === 'Fingerprint') {

            await completeCheckout(fingerprintOption).then(
                response => {
                    if (response.data.order.order_status == "closed") {
                        mixpanel.track("Closed Sales", {
                            saleData: fingerprintOption,
                            cashier: localStorage.getItem("username"),
                            time: new Date().toISOString(),
                            store: localStorage.getItem("storeName"),
                            paymentChoice: "fingerprint"
                        })
                        setLoading(false)
                        setOpenAlert(true)
                        setAlertMessage("Order Completed")
                        // setOrderNumber(response.data.id)
                        dispatch(emptyCart())
                        setDisableOrderButton(true)
                        setCardCode("")
                        setCardPin("")
                        if (!printFlag) {
                            window.print()
                            printFlag = true // Set flag to true after printing
                        }
                        cardcode = ""
                        localStorage.removeItem("CardCode")
                        navigate("/pos")

                    }

                }
            ).catch(error => {
                setLoading(false)
                 if (error.response && error.response.status === 401) {
                            setOpenLoaderDialogue(false)
                            setOpenAlert(true)
                            setAlertMessage('Your token has expired')
                            Cookies.remove('loggedState')
                            Cookies.remove('bearer')
                            navigate('/ ')
                            localStorage.clear()
                        }
                else {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('POS encountered an error. Please try again')

                }
            })
        } else if (paymentMethod === 'Credit') {

            completeCheckout(mealPlanOption).then(
                response => {
                    
                    if (response.data.message == "Pin Mismatch") {
                        setOpenAlert(true)
                        setAlertMessage("Customer entered a wrong pin")
                        setLoading(false)
                    } else if (response.data.order.order_status == "closed") {
                       
                        mixpanel.track("Closed Sales", {
                            saleData: mealPlanOption,
                            cashier: localStorage.getItem("username"),
                            time: new Date().toISOString(),
                            store: localStorage.getItem("storeName"),
                            paymentChoice: "pin"
                        })
                        setOrderNumber(response.data.id)
                        setLoading(false)
                        setOpenAlert(true)
                        setAlertMessage("Order Completed")
                        dispatch(emptyCart())

                        setDisableOrderButton(true)
                        setCardCode("")
                        setCardPin("")
                        if (!printFlag) {
                            window.print()
                            printFlag = true // Set flag to true after printing
                        }
                        cardcode = ""
                        localStorage.removeItem("CardCode")
                        navigate("/pos")
                    }

                }
            ).catch(error => {
                setLoading(false)
                if (error.response.status === 401) {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('Your token has expired')
                    Cookies.remove('loggedState')
                    Cookies.remove('bearer')
                    navigate('/ ')
                    localStorage.clear()
                }
                else {
                    
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('POS encountered an error. Please try again')

                }
            })
        } else if (paymentMethod === 'Cash') {
            completeCheckout(cashOption).then(
                response => {
                    if (response.data.order.order_status == "closed") {
                        mixpanel.track("Closed Sales", {
                            saleData: mealPlanOption,
                            cashier: localStorage.getItem("username"),
                            time: new Date().toISOString(),
                            store: localStorage.getItem("storeName"),
                            paymentChoice: "cash"
                        })
                        setLoading(false)
                        setOpenAlert(true)
                        setOrderNumber(response.data.id)
                        setAlertMessage("Order Completed")
                        if (!printFlag) {
                            window.print()
                            printFlag = true // Set flag to true after printing
                        }
                        dispatch(emptyCart())
                        setAmountPaid(' ')
                        setChange(0)
                        navigate("/pos")
                    } else {
                        setOpenLoaderDialogue(false)
                        setOpenAlert(true)
                        setAlertMessage("Error completing transaction, please try again")
                    }


                }
            ).catch(error => {
                setLoading(false)
                if (error.response.status === 401) {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('Your token has expired')
                    Cookies.remove('loggedState')
                    Cookies.remove('bearer')
                    navigate('/ ')
                    localStorage.clear()
                }
                else {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('POS encountered an error. Please try again')

                }

            })
        }

    }

    useEffect(() => {

        if (paymentMethod == 'Cash') {
            setShowMealPlanForm(false)

            setSubscriberName("-")
            setCardCode("")
        } else {
            setShowMealPlanForm(true)

        }

        if (amountPaid >= getTotal().totalPrice) {
            setDisableOrderButton(false)
        } else {
            setDisableOrderButton(true)
        }

        if (amountPaid === 0) {
            setDisableOrderButton(true)
        }
        if (paymentMethod === "Credit" || paymentMethod === "Fingerprint") {
            setDisableOrderButton(true)
        }
    },
        [paymentMethod, amountPaid])

    useEffect(() => {
        cardcode = localStorage.getItem("CardCode")
        cardcode ? getBalance(cardcode) : null
        cardcode ? setCardCode(cardcode) : null
        setOrderNumber(generateOrderNumber());
    }, []);


    useEffect(() => {
        const storeLogger = localStorage.getItem('userStoreID')
        if (!storeLogger) {
            navigate("/select")
        }
    })

    return (
        <Dashboard>

            <div className={classes.mainContainer}>

                <Grid container spacing={2}>
                    {/* Product Grid */}

                    <Grid item xs={12} md={8} lg={9} sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyItems: 'center' }}>

                        <div className={extraclass.chargeContainer}>

                            <div className={extraclass.chargeContainerText}>
                                <p style={{ color: 'white' }}>Amount Due </p><h2 style={{ fontWeight: 'bold', color: "white" }}>GH&#162; {getTotal().totalPrice.toFixed(2)}</h2>
                            </div>

                        </div>

                        <div className="">

                        </div>

                        <div className={extraclass.orderForm}>
                            <form onSubmit={handlePaymentForm}>
                                <div className={extraclass.orderFormPaymentSelection}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>Payment Option</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={paymentMethod}
                                            onChange={getPaymentMethod}
                                        >
                                            {paymentChoices.cashChoice === 1 ? <FormControlLabel value="Cash" control={<Radio />} label="Cash" /> : null}
                                            {paymentChoices.fingerprintChoice === 1 ? <FormControlLabel value="Fingerprint" control={<Radio />} label="Fingerprint" /> : null}
                                            {paymentChoices.mealPlanChoice === 1 ? <FormControlLabel value="Credit" control={<Radio />} label="OTP" /> : null}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {showMealPlanForm ? (
                                    <div className={extraclass.mealplanForm}>
                                        <div className={extraclass.orderFormSubscriberName}>
                                            <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>
                                                Profile
                                            </p>
                                            <div >
                                                <p style={{ fontSize: 20, marginTop: 10 }}>
                                                    {subscriberName}
                                                </p>
                                            </div>
                                        </div>

                                        <div className={extraclass.orderFormBalance}>
                                            <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }} >
                                                Current Balance
                                            </p>
                                            <div >
                                                <p style={{ fontSize: 20, marginTop: 10 }}>
                                                    GH&#162; {subscriberBalance}
                                                </p>
                                            </div>
                                        </div>

                                        <div className={extraclass.orderFormCardCodeContainer}>
                                            <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>
                                                Card Code
                                            </p>
                                            <FormControl fullWidth sx={{ width: '100%' }}>

                                                <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-code"
                                                    startAdornment={<InputAdornment position="start">ID</InputAdornment>}
                                                    label="Amount"
                                                    value={cardcode}
                                                    onChange={handleSubscriberCardCodeEntry}
                                                    inputProps={{ autoComplete: "off" }} 
                                                />

                                            </FormControl>
                                            {/* <Button onClick={getSubscriberInfoViaButton} variant="outlined" startIcon={<MdPersonSearch />} style={{ marginTop: "5px" }} >Find Subscriber</Button> */}
                                        </div>
                                        {
                                            paymentMethod === "Fingerprint" && (
                                                <div className="" style={{ marginTop: "25px" }}>
                                                    <Button id="captureprint" onClick={initiateAndVerifyFingerCapture} variant="outlined" startIcon={<IoMdFingerPrint />} disabled={disableFingerCapture}>Capture Fingerprint</Button>
                                                </div>

                                            )
                                        }
                                        {paymentMethod === "Credit" && (
                                            <div className={extraclass.orderFormPinContainer}>
                                                <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>
                                                    Card Pin
                                                </p>
                                                <FormControl fullWidth sx={{ width: '100%' }}>

                                                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        startAdornment={<InputAdornment position="start">Pin</InputAdornment>}
                                                        label="Pin"
                                                        value={cardPin}
                                                        onChange={getCardPin}
                                                        type='password'
                                                        disabled={disablePinInput}
                                                        inputProps={{ autoComplete: "off" }} 
                                                    />
                                                </FormControl>
                                            </div>

                                        )}






                                    </div>
                                ) : (
                                    <div className={extraclass.cashForm}>
                                        <div className={extraclass.orderFormAmountContainer}>
                                            <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>
                                                Amount
                                            </p>
                                            <FormControl fullWidth sx={{ width: '100%' }}>

                                                <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">GHC</InputAdornment>}
                                                    label="Amount"
                                                    onChange={getAmountPaid}
                                                />
                                            </FormControl>
                                        </div>


                                        <div className={extraclass.orderFormChange}>
                                            <p style={{ marginBottom: '10px', fontSize: 20, fontWeight: "bold", color: secondaryColour }}>
                                                Change
                                            </p>
                                            <div >
                                                <p style={{ fontSize: 25, marginTop: 10 }}>
                                                    GHc {amountPaid < getTotal().totalPrice || amountPaid == " " ? 0 : (amountPaid - getTotal().totalPrice).toFixed(2)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                <LoadingButton
                                    disabled={disableOrderButton}
                                    color="secondary"
                                    size="large"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<MdOutlineShoppingCartCheckout />}
                                    variant="contained"
                                    type='submit'
                                    sx={{ width: "100%", margin: "15px 0", height: 60, borderRadius: "10px", backgroundColor: "rgb(6 149 217)" }}
                                >
                                    Complete Order
                                </LoadingButton>
                            </form>


                        </div>




                    </Grid>


                    {/* Cart Grid */}
                    <Grid item xs={12} md={4} lg={3} className={extraclass.receipt} sx={{ backgroundColor: 'white', height: 'auto', p: 3 }}>
                        
                        <div className={extraclass.orderSummaryContainer}>
                            <div className={extraclass.orderNumberContainer}>
                                <h3 style={{ color: secondaryColour }}>Order Number : #{paymentMethod === "Credit" ? mealPlanOption.orderNo : paymentMethod === "Fingerprint" ? fingerprintOption.orderNo : paymentMethod === "Cash" ? cashOption.orderNo : 0}</h3>
                            </div>

                            <div className={extraclass.orderDetailContainer}>

                                <div className={extraclass.orderDetailHeader}>
                                    <h3 style={{ color: secondaryColour }}>Order Detail</h3>
                                </div>

                                <div className={extraclass.orderDetails}>
                                    <p>Customer Name :{subscriberName}</p>
                                    <p>Card Code : {cardCode}</p>
                                    <p>Store : {localStorage.getItem('storeName')}</p>
                                    <p>Cashier : {localStorage.getItem('username')}</p>
                                    <p>Order Type : Walk-in</p>
                                    <p>Payment Type : {paymentMethod}</p>
                                    <p>Date: {receiptDate.getDate()} / {(receiptDate.getMonth())+1} / {receiptDate.getFullYear()} </p>
                                    <p>Time: {receiptDate.getHours()}:{receiptDate.getMinutes()} </p>
                                </div>

                                <div className={extraclass.orderItemDetailHeader}>
                                    <h3 style={{ color: secondaryColour }}>Order Items</h3>
                                </div>

                                <div className={extraclass.orderItemDetails}>
                                    <div className={extraclass.item}>
                                        <table className={extraclass.blueTable}>
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart?.map(item => {
                                                    return (
                                                        <tr style={{fontWeight:"bold"}}>
                                                            <td>{item.name}</td>
                                                            <td>{item.qty}</td>
                                                            <td>GHC {parseFloat(item.cost*item.qty).toFixed(2)}</td>

                                                        </tr>
                                                    )
                                                })}



                                            </tbody>
                                            <tfoot>

                                            </tfoot>
                                        </table>
                                        

                                    </div>


                                </div>





                            </div>

                            <div className={extraclass.orderAmountContainer}>
                                <div className={extraclass.orderSubtotalContainer}>
                                    <div className="">
                                        <p style={{ color: secondaryColour }}>Subtotal</p>
                                    </div>
                                    <div className="">
                                        <p>GHC {getTotal().totalPrice.toFixed(2)}</p>
                                    </div>


                                </div>

                                <div className={extraclass.orderTotalContainer}>
                                    <div className="">
                                        <p style={{ color: secondaryColour }}>Total</p>
                                    </div>
                                    <div className="">
                                        <p>GHC {getTotal().totalPrice.toFixed(2)}</p>
                                    </div>


                                </div>
                            </div>

                            {paymentMethod == 'Cash' && (
                                <div className={extraclass.orderChangeContainer}>
                                <div className={extraclass.orderSubtotalContainer}>
                                    <div className="">
                                        <p style={{ color: secondaryColour }}>Amount Paid</p>
                                    </div>
                                    <div className="">
                                        <p>GHC {amountPaid.toFixed(2)}</p>
                                    </div>


                                </div>

                                <div className={extraclass.orderTotalContainer}>
                                    <div className="">
                                        <p style={{ color: secondaryColour }}>Change</p>
                                    </div>
                                    <div className="">

                                        <p>GHC {amountPaid < getTotal().totalPrice ? 0 : (amountPaid - getTotal().totalPrice).toFixed(2)}</p>
                                    </div>


                                </div>
                            </div>
                            )}
                            
                        </div>

                    </Grid>
                </Grid>
                <CloseRegisterForm></CloseRegisterForm>
                <AlertComponent open={openAlert} message={alertMessage} close={closeAlert} />
                <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
                <BiometricScanDialogue open={openBiometricScanDialogue} message={biometricScanDialogueText} printVerified={biometricVerificationPassed} printNotVerified={biometricVerificationFailed} close={closeBiometricScanDialogue} />
                

            </div>
        </Dashboard>

    )
}

export default CheckoutPage
