import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { TiCancelOutline } from "react-icons/ti";
import CancelOrderDialogue from './cancelOrderDialogueComponent.jsx';





export default function OrdersDataTable(props) {

  const loadAllSales = () => {
    props.loadSales()
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'orderid', headerName: 'orderID', width: 100 },

    { field: 'subscriberID', headerName: 'Subscriber ID', width: 200 },
    {
      field: 'lastname',
      headerName: 'Last Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'ordertype',
      headerName: 'Order Type',
      type: 'string',
      width: 200,
    },
    {
      field: 'total',
      headerName: 'Total Charge',
      type: 'string',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date & Time',
      type: 'string',
      width: 200,
    },
    {
      field: 'cancelorder',
      headerName: 'Cancel Order',
      width: 200,
      renderCell: (params) => (
        <CancelOrderDialogue tableData = {params.row} loadSales = {loadAllSales}/>
      ),
    },
  ];

  const rows = [
  ];
  props.tableData.map(
    (item,index)=>{
      const rowItem = {id:index, orderid:item.id,subscriberID:item.card_code,firstname:item.firstname,lastname:item.lastname,ordertype:item.payment_method,total:item.total_amount,date:item.date}
      rows.push(rowItem)
    }
  )
  return (
    <div style={{ height: 400, width: '95%' , backgroundColor:"white"}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div>
  );
}
